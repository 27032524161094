.footer {
    background-color: #00686a;
    display: flex;
    color: #ffffff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 4rem 2rem 4rem;
    font-family: var(--font-family);
    width: 100vw;
}

.footer-copyright {
    padding: 2rem 0 .5rem;
}

.footer-copyright p {
    font-size: 14px;
    line-height: 20px;
}
.footer-heading {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
}

.footer-heading h1 {
    font-weight: 700;
    font-size: 38px;
    line-height: 38px;
}

.footer-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}

.footer-links-div {
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-links-div h4 {
    font-size: 20px;
    line-height: 26px;
}

.footer-links-div p {
    font-size: 16px;
    line-height: 20px;
    margin-top: 5px;
}

/* @media screen and (max-width: 1000px) {
    .footer {
        width: 105vw;
    }
} */

/* @media screen and (max-width: 900px) {
    
    
    .footer-heading h1 {
        font-size: 36px;
        line-height: 36px;
    }
    
    .footer-copyright p {
        font-size: 14px;
        line-height: 20px;
    }

    .footer-links-div h4 {
        font-size: 18px;
        line-height: 26px;
    }
} */

/* @media screen and (max-width: 800px) {
    .footer {
        width: 105%;
    }
} */

    @media screen and (max-width: 567px) {
    
    .footer {
        padding: 2rem 2rem 2rem 2rem;
    }


    .footer-links-div p {
        font-size: 14px;
        line-height: 20px;
    }
}

/* @media screen and (max-width: 500px) {
    
    .footer {
        width: 124%;
    }
}

@media screen and (max-width: 400px) {
    
    .footer {
        width: 125%;
    }
} */