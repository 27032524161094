.features-container-feature {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    margin: 1rem 1rem 1rem 1rem;
}

.features-container-feature-title {
    display: flex;
    justify-content:flex-end;
    flex: 1;
    max-width: 180px;
}

.features-container-feature-title h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 50px;
    line-height: 24px;
    letter-spacing: -0.04em;
    padding-right: 1.5rem;
    color: #ff0a7f;
}

.features-container-feature-title div {
    width: 38px;
    height: 3px;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    margin-bottom: 0.25rem;
}

.features-container-feature-text {
    flex: 2;
    max-width: 390px;
    display: flex;
}

.features-container-feature-text p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 28px;
    line-height: 32px;
    color: #286f73;
    /* color: var(--color-text); */
}

@media screen and (max-width: 1200px) {
    .features-container {
        padding-top: .5rem;
    }
}

@media screen and (max-width: 992px) {
    .features {
        margin: 0rem;
    }

    .features-container-feature {
        padding-right: 10%;
        padding-left: 3rem;
    }

    .features-container-feature-title h1 {
        font-size: 40px;
      line-height: 24px;
    }

    .features-container-feature-text p {
        font-size: 20px;
        line-height: 30px;
    }
}

/* @media screen and (max-width: 550px) {
    .features-container-feature-title h1 {
        font-size: 28px;
        line-height: 22px;
    }

    .features-container-feature-text p {
        font-size: 16px;
        line-height: 20px;
    }

    .features-container-feature {
        margin: 1rem 0;
    }
} */