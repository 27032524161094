
.services-container {
    display: flex;
    flex-direction: row;
}

.services-container-service {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;

    margin: 1rem;
}

.services-container-service-title {
    display: flex;
    justify-content: flex-start;
    max-width: 500px;
}

.services-container-service-title h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;
    margin: .5rem 0;
    color: #286f73;
}

.services-container-service-title div {
    width: 38px;
    height: 3px;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    margin-bottom: 0.25rem;
}

.services-container-service-text {
    flex: 1;
    max-width: 800px;
    display: flex;
}

.services-container-service-text p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 28px;
    line-height: 46px;
    margin-bottom: 1rem;
    color: #286f73;
    /* color: var(--color-text); */
}

@media screen and (max-width: 1200px) {

    .services-container-service-title h1 {
        font-size: 40px;
        line-height: 60px;
    }

    .services-container-service-text p {
        font-size: 22px;
        line-height: 33px;
    }
}


@media screen and (max-width: 992px) {
    .services-container-service-title h1 {
        font-size: 28px;
        line-height: 40px;       
    }

    .services-container-service-text p {
        
        font-size: 20px;
        line-height: 30px;
        margin-top: 1rem;
        margin-bottom: 0rem;
        
    }

    .services-container-service {
        margin: 1rem 0;
    }
}