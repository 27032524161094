.cta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    background: #00686a;
    border-radius: 15px;

    padding: 1.5rem 2rem;
    margin: 2rem;
}

.cta-content p{
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 22px;
    line-height: 34px;


    color: #ffffff;
}

.cta-content h3{
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;

    color: #ffffff;
}

.cta-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1.5rem;
}

.cta-button button{
    background: #ff0a7f;
    border-radius: 15px;
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;

    border: none;
    color: #ffffff;
    padding: 1rem 1.5rem;
    outline: none;
    cursor: pointer;
    min-width: 100px;
}

@media screen and (max-width: 1200px) {

    .cta-button button{
        min-width: 200px;
    }
}

@media screen and (max-width: 768px) {
    .cta {
        flex-direction: column;
    }

    .cta-button {
        margin-top: .5rem;
    }
}

@media screen and (max-width: 576px) {

    .cta {
        margin: 2rem 1rem;
    }

    .cta-button {
        margin: 1rem 0 0;
    }

    .cta-button button{
        font-size: 20px;
        line-height: 28px; 
        padding: 1rem 0; 
      }

}
