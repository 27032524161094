.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* position: sticky; */
    top: 0;

    padding: .5rem 3rem;
    background-color: #e5feff;
    /* border-bottom: solid 1px;
    border-color: #000000; */
}

.navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.navbar-links-logo {
    margin-right: 2rem;
}

.navbar-links-logo h1 {
    margin-top: 1rem;
    height: 4rem;
    font-family: var(--font-family);
    font-weight: 800;
    color: #00686a;
    font-size: 42px;
}

.navbar-links-container {
    display: flex;
    flex-direction: row;
}

.navbar-links-container p,
.navbar-menu-container p{
font-family: var(--font-family);
font-weight: 500;
font-size: 18px;
line-height: 25px;
text-transform: capitalize;
color: #00686a;

margin: 0 1rem;
cursor: pointer;
}

.navbar-links-container p:hover,
.navbar-menu-container p:hover {
    color: #ff0a7f;
}

.navbar-menu-container button{
    padding: 0.5rem 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;  
}

.navbar-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
}

.navbar-menu svg{
    cursor: pointer;
}

.navbar-menu-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    
    text-align: end;
    /* background: #00686a; */

    padding: 1rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: .5rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0,0,0,0.2);
    background-color: #e5feff; 
}

.navbar-menu-container p {
    margin: 1rem 0;
}

/* @media screen and (max-width: 1050px)
{
    .navbar-links-container {
        display: none;
    }

    .navbar-menu {
        display: flex;
    }
} */

/* @media screen and (max-width: 700px) {
    

    .navbar-links-logo h1 {
      height: 3rem;
      margin-top: 0rem;
    }

    .navbar {
        padding: 1rem 2rem;
    }
  } */
  
  /* @media screen and (max-width: 550px) {
    .navbar-links-logo h1 {
      height: 3rem;
    }
    .navbar {
        padding: 1rem 2rem;
    }

    .navbar-menu-container {
        top: 20px;
    }
  } */

  /* @media screen and (max-width: 450px) {
    .navbar-links-logo h1 {
      height: 2.5rem;
      font-size: 28px;
    }
    .navbar {
        padding: 1rem 2rem;
    }
  } */