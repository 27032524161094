.card-container-card {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    margin: 1rem;

    cursor: pointer;

}

.card-container-card-img img{
    flex: 1;
    max-width: 18rem;
    border-radius: 25px;
}

.card-container-card-img div {
    width: 38px;
    height: 3px;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    margin-bottom: 0.25rem;
}

.card-container-card-title {
    flex: 1;
    display: flex;
    margin-top: .5rem;
}

.card-container-card-title p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #00686a;
    /* color: var(--color-text); */
}

@media screen and (max-width: 1200px) {
    .card-container-card-title p {
        font-size: 22px;
        line-height: 36px;
    }

    .card-container-card div {
        margin: .5rem;
    }
}

@media screen and (max-width: 992px) {
    
.tour {
    display: flex;
    align-items: center;
    justify-content: center;
}

    .tour-nav {
        max-width: 16rem;
    }

    .card-container-card {
        margin: 1rem 1rem;
    }

    .card-container-card-img img{
        flex: 1;
        max-width: 14rem;
        border-radius: 25px;
    }
}

@media screen and (max-width: 768px) {
    .card-container-card-title p {
        font-size: 20px;
        line-height: 30px;
    }

}