.features {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 4rem 2rem 4rem 2rem;
    background-color: #e5feff;

}
/* #e5feff */
.features-heading {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 4rem;
}

.features-heading h1 {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 60px;
    margin-top: 1rem;
    color: #286f73;
    /* margin-right: 1rem; */
}

.features-heading p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 42px;
    margin-top: 2rem;
    margin-bottom: 1rem;
    color: #286f73;
}

.features-container {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

@media screen and (max-width: 1200px) {
    .features {
        flex-direction: column;
        padding: 4rem 2rem 4rem 1rem;
    }
    .features-heading {
        margin: 0 0 2rem 0;
    }

    .features-heading h1 {
        font-weight: 800;
        font-size: 36px;
        line-height: 54px;
    }

    .features-heading p {
        font-size: 22px;
        line-height: 33px;
        margin-top: 1.5rem;
        margin-bottom: 0rem;
    }
}

@media screen and (max-width: 768px) {
    .features {
        flex-direction: column;
        padding: 4rem 4rem 4rem 0rem;
    }
}

@media screen and (max-width: 576px) {
    
    .features {
        padding-left: 0rem;
    }

    .features-heading {
        margin: 0 0 2rem 0;
    }
   
    .features-heading h1 {
        font-weight: 800;
        font-size: 32px;
        line-height: 48px;
    }

    .features-heading p {
        font-size: 20px;
        line-height: 30px;
        margin-top: 1rem;
        margin-bottom: 0rem;
    }

}

/* @media screen and (max-width: 550px) {
  
    .features {
        padding: 1rem 4rem;
    }
  
    .features-heading h1{
    font-size: 28px;
    line-height: 32px;
  }

  .features-heading p{
    font-size: 24px;
    line-height: 28px;
  }
} */

/* @media screen and (max-width: 450px) {
    
    .features {
        padding: .5rem 2rem;
    }

    .features-heading h1{
      font-size: 24px;
      line-height: 30px;
    }

    .features-heading p {
        font-size: 20px;
        line-height: 24px;
    }
  } */