.services {
    display: flex;
    flex-direction: column;

    padding: 2rem 4rem;
    background-color: #e5feff;

}

.services-feature h1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    margin: 0 0 1rem;
}

.services-feature h1 {
    font-size: 48px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    max-width: 510px;
    color: #00686a;
}

.services-feature p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: #00686a;
}

/* Customizing feature component as per needs */
.services-feature .services-container-service {
    margin: 0;
}

/* Customizing feature component as per needs */
.services-feature .services-container-service-text {
    max-width: 700px;
}

.services-feature {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.services-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    max-width: 510px;
}

.services-heading p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: var(--color-subtext);
    cursor: pointer;
}

.services-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    margin-top: .5rem;
}

/* Customizing feature component as per needs */
.services-container .services-container-service {
    flex: 1;
    margin: 1rem;
    min-width: 210px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Customizing feature component as per needs */
.services-container .services-container-service-text {
    margin-top: 0.5rem;
}



@media screen and (max-width: 992px) {
    .services-feature {
        flex-direction: column;
        align-items: center;
        margin-bottom: 0rem;
    }

    .services-container {
        display: flex;
        flex-direction: column;
        flex: 1;
        min-width: 210px;
        display: flex;
        align-items: center;
    }

    .services-feature h1 {
        font-weight: 800;
        font-size: 36px;
        line-height: 54px;
        padding-left: 5%;
    }


}

@media screen and (max-width: 768px) {
   
    .services-feature .services-container-service {
        flex-direction: column;
    }

    .services-feature h1 {
        font-size: 32px;
        line-height: 48px;
    }
}

@media screen and (max-width: 576px) {
    
    .services {
        padding: 2rem;
        margin: .5rem;
    }
    
    .services-container-service {
        padding-right: 2rem;
    }
}

/* @media screen and (max-width: 350px) {
    
    .services-container .services-container-service {
        margin: 1rem;
        min-width: 100%;
        padding-right: 1rem;
    }
} */