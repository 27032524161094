.btn-modal {
    border: none;
    background: none;
    margin-top: 5px;
}

.btn-modal p{
    font: (var(--font-family));
    color: white;
    font-size: 16px;
}

.modal-content button{
    background: none;
    color: #ffffff;
    border: none;
}