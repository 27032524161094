
.header {
    display: flex;
    flex-direction: row;
    background-color: #e5feff; 
    padding: 4rem 2rem 4rem 6rem;
}

.header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 3rem;
    padding-right: 3rem;
}

.header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 48px;
    line-height: 72px;
    color: #ff0a7f;
}
/* #286f73 */
.header-content p {
    font-family: var(--font-family);
    font-size: 30px;
    line-height: 45px;
    font-weight: 400;
    margin-top: 1.5rem;
    color: #00686a;
}

.header-image {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header-image img {
    width: 100%;
    max-height: 600px;
}

.header-content-input {
    width: 100%;
    margin: 2rem 0 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.header-content-input button {
   flex: 0.6;
   width: 100%;
   max-height: 100px;
   font-family: var(--font-family);
   font-weight: 600;
   font-size: 28px;
   line-height: 28px;    
   background-color: #00686a;
   border: none;
   padding: 1rem 2rem;
   color: #ffffff;
   cursor: pointer;
   outline: none;
   /* border-top-right-radius: 5px;
   border-bottom-right-radius: 5px; */
   border-radius: 15px;
   margin-top: 1.5rem;
} 

@media screen and (max-width: 1200px) {
    .header {
        padding: 4rem 2rem 4rem 2rem;
    }

    .header-content h1 {
        font-size: 40px;
        line-height: 60px;
    }

    .header-content p {
        font-size: 22px;
        line-height: 33px;
    }

    .header-content-input button {
        font-size: 22px;
        line-height: 22px;
    }
}

@media screen and (max-width: 992px) {
    .header {
        padding: 2rem 3rem;
    }

    .header-content h1 {
        font-size: 36px;
        line-height: 54px;
    }

    .header-content p {
        font-size: 20px;
        line-height: 30px;
    }

    .header-content-input button {
        font-size: 20px;
        line-height: 20px;
        margin-top: 1rem;
    }

    .header-image img {
        width: 100%;
        max-height: 400px;
        margin-top: 2rem;
    }
}

@media screen and (max-width: 768px) {
    
    .header {
        padding: 4rem 4rem 4rem 4rem;
        flex-direction: column;
    }
    
    .header-content {
        padding-right: 0px;
        margin-right: 0px;
    }

    .header-content-input button {
        font-size: 20px;
        line-height: 20px;
        margin-top: .5rem;
    }
}