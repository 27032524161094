.portfolio {
    padding: 2rem 6rem;
    background-color: #e5feff;
}

.portfolio-heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.portfolio-heading p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 28px;
    line-height: 42px;
    color: #00686a;
}

.portfolio-heading h1 {
    font-size: 40px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    max-width: 510px;
    margin-bottom: 1rem;
    color: #00686a;
}

.portfolio-cards {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    justify-content: center;
    align-items: center;
}


@media screen and (max-width: 1200px) {
    
    .portfolio-heading h1 {
        font-weight: 800;
        font-size: 36px;
        line-height: 54px;
        margin-bottom: 0;
    }

    .portfolio-heading p {
        font-size: 22px;
        line-height: 33px;
        margin-top: 1rem;
        margin-bottom: 0rem;
    }
    
    .portfolio {
        flex-direction: column;
        padding: 2rem 3rem;
    }

    .portfolio-cards {
        padding: 0 2rem 1rem 2rem;
        display: flex;
        flex-direction: column;
    }
}



@media screen and (max-width: 768px) {
    
    .portfolio {
        padding: 1rem 2rem;
    }
    

    
    .portfolio-heading h1{
        font-size: 28px;
        line-height: 40px;
    }

    
    .portfolio-heading p{
        font-size: 20px;
        line-height: 30px;
    }

    .portfolio-cards {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 576px) {
    
    .portfolio {
        padding: .5rem 2rem;
    }

    .portfolio-heading p {
        padding: 0 1rem;
    }
}
