* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: #e5feff
}

a {
  color: unset;
  text-decoration: none;
}

/* 
.section-padding {
  padding: 4rem 6rem;
}

.section-margin {
  padding: 4rem 6rem;
} */

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

