.testimonials {
}

.testimonials-heading {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.testimonials-heading p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
}

.testimonials-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    max-width: 510px;
}

.carousel-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.carousel-root {
    max-width: 400px;
    padding: 2rem;
}

.carousel-status {
    display: none;
}

.control-arrow {
    display: none;
}

.control-next {
    display: none;
}

.control-prev {
    display: none;
}

.control-dots {
    display: none;
}

.carousel .slide {
    height: 40vh;
}

.carousel .slide img{
    max-height: 100%;
    width: auto;
}

.thumbs {
    display: none;
}

.thumb {
    display: none;
}